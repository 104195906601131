import Image from "next/image";
import { Center, Box, Text, Button } from "@chakra-ui/react";
import Link from "next/link";
import { useTranslation } from "react-i18next";

export default function Custom404() {
  const { t } = useTranslation("translation", { keyPrefix: "ErrorPage" });
  return (
    <Center display="flex" flexDirection="column" height="100vh">
      <Box margin={4}>
        <Image src="/404.svg" width={350} height={350} alt="Empty" />
      </Box>
      <Text margin={4} fontSize="xl" fontWeight="medium">
        {t("404")}
      </Text>
      <Link href="/admin">
        <Button margin={4}>{t("ctaBtn")}</Button>
      </Link>
    </Center>
  );
}
